import Grid from 'data/components/Grid';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AccordionUser from 'data/components/AccordionUser';
import Text from 'data/components/Text';
import { capitalize } from 'utils';
import PencilIcon from '../../../../assets/svgs/Pencil';
import Button from 'data/components/Button';
import { bindActionCreators } from 'redux';
import {
    recoverPassword,
    setNewPassword,
    updateUser,
} from '../../../../store/auth/authSlice';
import { connect } from 'react-redux';
import LoginFacebook from '../../Login/components/LoginFacebook';
import LoginGoogle from '../../Login/components/LoginGoogle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Home from '../../../../data/components/Scheme/Home';
import PinIcon from '../../../../assets/svgs/PinIcon';
import EuroIcon from '../../../../assets/svgs/EuroIcon';
import FormControlLabel from '@mui/material/FormControlLabel';
import MUISwitch from '@mui/material/Switch';
import { CustomThemeContext } from '../../../../theme/CustomThemeContext';
import TextField from '@mui/material/TextField';
import { ENV } from '../../../../theme/config';

const propTypes = {
    user: PropTypes.shape({
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        tel_port: PropTypes.string,
        tel_fixe: PropTypes.string,
        email: PropTypes.string,
        address: PropTypes.string,
        email_google: PropTypes.string,
        email_facebook: PropTypes.string,
    }),
};

const defaultProps = {
    user: {
        firstname: 'pierre',
        lastname: 'pierre',
        tel_port: '0606060606',
        tel_fixe: '0404040404',
        email: 'pierre@hotmail.fr',
        email_facebook: 'pierre@hotmail.fr',
        email_google: 'pierre@hotmail.fr',
        address: '9 rue denis papin',
    },
};

const UserCards = props => {
    const classes = useStyle();
    const { theme, setTheme } = useContext(CustomThemeContext);
    const [checked, setChecked] = useState(theme === `${ENV}_dark`);
    const [edition, setEdition] = useState(false);

    useEffect(() => {
        setTheme(checked ? `${ENV}_dark` : `${ENV}_light`);
    }, [checked]);

    const handleChange = event => {
        setChecked(event.target.checked);
    };

    const [port, setPort] = useState(props.user.tel_port);
    const [fixe, setFixe] = useState(props.user.tel_fixe);

    const updateUser = () => {
        if (edition) {
            setEdition(false);
            props.updateUser(props.user.id, {
                tel_port: port,
                tel_fixe: fixe,
                firstname: props.user.firstname,
                lastname: props.user.lastname,
                email: props.user.email,
                email_facebook: props.user.email_facebook,
                email_google: props.user.email_google,
                address: props.user.address,
            });
        } else if (!edition) {
            setEdition(true);
        }
    };

    return (
        <Grid container item className={classes.container}>
            <Grid item xs={12} sm={6} md={4}>
                <Card elevation={5}>
                    <CardContent className={classes.card}>
                        <AccordionUser
                            defaultExpanded={false}
                            header={
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.titleContainer}
                                    >
                                        <Text noWrap className={classes.title}>
                                            Profile
                                        </Text>
                                    </Grid>
                                </Grid>
                            }
                            content={
                                <Grid container className={classes.content}>
                                    <Box
                                        className={classes.box}
                                        component="form"
                                    >
                                        <TextField
                                            label={'Nom'}
                                            defaultValue={`${capitalize(
                                                props.user.lastname,
                                            )} ${props.user.firstname}`}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                        <TextField
                                            className={classes.row}
                                            onChange={e =>
                                                setPort(e.target.value)
                                            }
                                            label={'Portable'}
                                            defaultValue={
                                                port !== null ? `${port}` : ''
                                            }
                                            InputProps={{
                                                readOnly: !edition,
                                            }}
                                        />
                                        <TextField
                                            className={classes.row}
                                            onChange={e =>
                                                setFixe(e.target.value)
                                            }
                                            label={'Fixe'}
                                            defaultValue={
                                                fixe !== null ? `${fixe}` : ''
                                            }
                                            InputProps={{
                                                readOnly: !edition,
                                            }}
                                        />
                                    </Box>
                                    <Box className={classes.loginButtons}>
                                        <LoginFacebook link />
                                        <div style={{ width: '5%' }} />
                                        <LoginGoogle link />
                                    </Box>
                                    <Box className={classes.buttons}>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                updateUser();
                                            }}
                                            color="primary"
                                            variant="outlined"
                                            fullWidth
                                        >
                                            {!edition ? (
                                                <PencilIcon
                                                    width={20}
                                                    height={25}
                                                    color={'#00988E'}
                                                />
                                            ) : (
                                                'Enregistrer'
                                            )}
                                        </Button>

                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            fullWidth
                                            onClick={() => {}}
                                        >
                                            Réinitialiser le mot de passe
                                        </Button>
                                    </Box>
                                </Grid>
                            }
                        />
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <Card elevation={5}>
                    <CardContent className={classes.card}>
                        <AccordionUser
                            defaultExpanded={false}
                            header={
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.titleContainer}
                                    >
                                        <Text noWrap className={classes.title}>
                                            Foyer
                                        </Text>
                                    </Grid>
                                </Grid>
                            }
                            content={
                                <Grid container className={classes.content}>
                                    <Box className={classes.box}>
                                        <Home
                                            width={30}
                                            height={30}
                                            color={'#00988E'}
                                        />
                                        <Text noWrap className={classes.row}>
                                            {props.user.address}
                                        </Text>
                                    </Box>
                                    <Box className={classes.box}>
                                        <PinIcon
                                            width={30}
                                            height={30}
                                            color={'#00988E'}
                                        />
                                        <Text noWrap className={classes.row}>
                                            {props.user.weather_city}
                                        </Text>
                                    </Box>
                                    <Box className={classes.box}>
                                        <EuroIcon
                                            width={30}
                                            height={30}
                                            color={'#00988E'}
                                        />
                                        <Text noWrap className={classes.row}>
                                            Prix du kWh: {props.user.kWh_price}€
                                        </Text>
                                    </Box>
                                </Grid>
                            }
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Card elevation={5}>
                    <CardContent className={classes.card}>
                        <AccordionUser
                            defaultExpanded={true}
                            header={
                                <Text className={classes.title}>Theme</Text>
                            }
                            content={
                                <Grid container classNames={classes.content}>
                                    <FormControlLabel
                                        control={
                                            <MUISwitch
                                                checked={checked}
                                                color="primary"
                                                onChange={handleChange}
                                            />
                                        }
                                        label="Mode Sombre"
                                    />
                                </Grid>
                            }
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

const useStyle = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    card: {
        backgroundColor: theme.palette.background.default,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: 20,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    box: {
        display: 'flex',
        flexDirection: 'row',
        margin: 8,
        alignItems: 'center',
    },
    row: {
        marginLeft: 14,
        textAlign: 'baseline',
    },
    loginButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 8,
        marginBottom: 0,
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 80,
        marginTop: 0,
        margin: 8,
    },
}));

UserCards.propTypes = propTypes;
UserCards.defaultProps = defaultProps;

const mapDispatch = dispatch =>
    bindActionCreators(
        {
            updateUser,
            recoverPassword,
            setNewPassword,
        },
        dispatch,
    );

export default connect(null, mapDispatch)(UserCards);
