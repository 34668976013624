import * as React from 'react';

const BubbleIcon = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 443.541 443.541"
        style={{
            enableBackground: 'new 0 0 443.541 443.541',
        }}
        xmlSpace="preserve"
        width={props.width}
        height={props.height}
        {...props}
    >
        <path
            fill={props.color}
            d="M76.579 433.451V335.26C27.8 300.038 0 249.409 0 195.254 0 93.155 99.486 10.09 221.771 10.09s221.771 83.065 221.771 185.164-99.486 185.164-221.771 185.164a264.982 264.982 0 0 1-43.445-3.604L76.579 433.451z"
        />
    </svg>
);

export default BubbleIcon;
