import { createAsyncThunk } from '@reduxjs/toolkit';
import MiddlewareSocket from '../../MiddlewareSocket';
import { batch } from 'react-redux';
import { getHomeNetworkStats } from '../stats/statsSlice';
import { weathersActions } from '../weather/weatherSlice';
import { getTokenFromStorages, getWeather } from 'utils';
import { productsActions } from '../products/productsSlice';
import { generatorsActions } from '../generators/generatorSlice';
import {
    getPlanningEvents,
    setLastEvents,
} from '../planningEvents/planningEventsSlice';
import { categoriesActions } from '../categories/categoriesSlice';
import { binds } from 'MiddlewareSocket/listeners/listeners';
import Http from '../../utils/Http';
import { getHomeTickets } from '../tickets/ticketSlice';

export const login = createAsyncThunk('auth/login', async form => {
    const { email, password, rememberMe } = form;
    const { access_token } = await Http.post('auth', {
        email,
        password,
    });
    return { rememberMe, access_token };
});

export const loginWithProvider = createAsyncThunk('auth/login', async form => {
    const { code, provider, rememberMe } = form;
    const { access_token } = await Http.post('auth-provider', {
        provider_auth_code: code,
        provider_type: provider,
    });

    return { rememberMe, access_token };
});

export const init = createAsyncThunk(
    'auth/init',
    async (_, { dispatch, getState }) => {
        const { auth } = getState();
        const token = getTokenFromStorages();

        const payload = await Http.get('initialize');
        console.log('🟢', payload);
        const {
            weather,
            profile,
            generators,
            product_modules,
            planningEvents,
            middleware_config,
            product_modules_categories,
        } = payload;

        if (middleware_config) {
            binds(dispatch);
            MiddlewareSocket.connectToServer(
                middleware_config,
                auth.token || token,
            );
        }
        const hasProd = generators.length >= 1;

        await batch(async () => {
            await dispatch(setLastEvents(planningEvents));
            await dispatch(getHomeNetworkStats(true, 'hours', hasProd));
            await dispatch(getHomeTickets());
            await dispatch(weathersActions.setAll(getWeather(weather)));
            await dispatch(productsActions.setAll(product_modules));
            await dispatch(generatorsActions.setAll(generators));
            await dispatch(getPlanningEvents());
            await dispatch(
                categoriesActions.setAll(product_modules_categories),
            );
        });
        return { profile };
    },
);

export const linkProvider = createAsyncThunk(
    'auth/linkProvider',
    async form => {
        const { provider, code } = form;
        const { provider_email } = await Http.post('user/link-to-provider', {
            provider_type: provider,
            provider_auth_code: code,
        });
        return { provider, provider_email };
    },
);

export const unlinkProvider = createAsyncThunk(
    'auth/unlinkProvider',
    async provider => {
        await Http.post('user/unlink-to-provider', {
            provider_type: provider,
        });

        return { provider };
    },
);
