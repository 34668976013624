import { makeStyles } from '@mui/styles';
import { Box, TextField } from '@mui/material';
import t from '../../../../utils/Translator';
import React from 'react';
import Button from '../../Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { recoverPassword } from '../../../../store/auth/authSlice';
import Text from '../../Text';
import FormControl from '../../FormControl';
import LogoDark from '../../../../assets/svgs/logo_layout_dark.svg';
import Logo from '../../../../assets/svgs/logo_layout_light.svg';

const initialValues = {
    email: '',
};

const validationSchema = Yup.object({
    email: Yup.string().required('Enter a valid email'),
});

const PasswordRecoveryModal = props => {
    const classes = useStyles();
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            props.setRecoverEmail(values.email);
            await recoverPassword(values.email);
            props.togglerecoverandauthmodal();
            return props.close();
        },
    });

    return (
        <Box className={classes.container}>
            <Text className={classes.title}>Entrez votre email</Text>
            <form onSubmit={formik.handleSubmit} className={classes.form}>
                <FormControl className={classes.content}>
                    <TextField
                        fullWidth
                        variant="standard"
                        id="email"
                        name="email"
                        label={t.get('login.email')}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type={'submit'}
                        className={classes.submit}
                    >
                        Envoyer
                    </Button>
                </FormControl>
                <div className={classes.background} />
            </form>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    form: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '80%',
        width: '80%',
    },
    title: {
        fontSize: 32,
    },
    submit: {
        position: 'absolute',
        marginTop: 12,
        right: 0,
    },
    background: {
        position: 'fixed',
        width: '80%',
        height: '80%',
        background:
            theme.palette.mode === 'dark'
                ? `url(${LogoDark}) no-repeat center`
                : `url(${Logo}) no-repeat center`,
        backgroundColor: theme.palette.background.default,
        backgroundPosition: '50% 10%',
        backgroundSize: 400,
        zIndex: '-1',
    },
}));

export default PasswordRecoveryModal;
