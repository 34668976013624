import React from 'react';
import TicketHistory from './components/TicketHistory';
import TicketWizard from './components/TicketWizard';

const TicketModal = props => {
    return (
        <>
            {props.ticket ? (
                <TicketHistory ticket={props.ticket} close={props.close} />
            ) : (
                <TicketWizard
                    close={props.close}
                    createTicket={props.createTicket}
                />
            )}
        </>
    );
};

export default TicketModal;
