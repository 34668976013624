import { createTheme } from '@mui/material/styles';

const fhe_light = createTheme({
    palette: {
        primary: {
            main: '#00988E',
        },
        secondary: {
            main: '#fff',
        },
        dark: {
            main: '#111',
        },
        background: {
            default: '#fff',
        },
        text: {
            primary: '#111',
        },
        mode: 'light',
    },
});

const fhe_dark = createTheme({
    palette: {
        primary: {
            main: '#00988E',
        },
        secondary: {
            main: '#fff',
        },
        dark: {
            main: '#111',
        },
        background: {
            default: '#444',
        },
        text: {
            primary: '#fff',
        },
        backgroundImage: {
            color: '#0000',
        },
        mode: 'dark',
    },
});

const sweetom_light = createTheme({
    palette: {
        primary: {
            main: '#d62e1d',
        },
        secondary: {
            main: '#fff',
        },
        dark: {
            main: '#111',
        },
        background: {
            default: '#fff',
        },
        text: {
            primary: '#111',
        },
        mode: 'light',
    },
});

const sweetom_dark = createTheme({
    palette: {
        primary: {
            main: '#d62e1d',
        },
        secondary: {
            main: '#fff',
        },
        dark: {
            main: '#111',
        },
        background: {
            default: '#444',
        },
        text: {
            primary: '#fff',
        },
        backgroundImage: {
            color: '#0000',
        },
        mode: 'dark',
    },
});

const mgHabitat_light = createTheme({
    palette: {
        primary: {
            main: '#0b7d41',
        },
        secondary: {
            main: '#fff',
        },
        dark: {
            main: '#111',
        },
        background: {
            default: '#fff',
        },
        text: {
            primary: '#111',
        },
        mode: 'light',
    },
});

const mgHabitat_dark = createTheme({
    palette: {
        primary: {
            main: '#0b7d41',
        },
        secondary: {
            main: '#fff',
        },
        dark: {
            main: '#111',
        },
        background: {
            default: '#444',
        },
        text: {
            primary: '#fff',
        },
        backgroundImage: {
            color: '#0000',
        },
        mode: 'dark',
    },
});

const themes = {
    fhe_light,
    fhe_dark,
    sweetom_light,
    sweetom_dark,
    mgHabitat_light,
    mgHabitat_dark,
};

export default themes;
