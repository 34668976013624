import * as React from 'react';

const TagIcon = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style={{
            enableBackground: 'new 0 0 512 512',
        }}
        width={props.width}
        height={props.height}
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill={props.color}
            d="M507.137 249.793 263.443 4.927A16.655 16.655 0 0 0 251.606.003H16.695C7.467.003 0 7.47 0 16.698v233.737c0 4.434 1.761 8.674 4.892 11.804l244.867 244.867c6.521 6.521 17.087 6.521 23.609 0l233.737-233.737c6.521-6.521 6.521-17.054.032-23.576zm-390.268-82.835c-27.619 0-50.087-22.468-50.087-50.086s22.468-50.087 50.087-50.087c27.619 0 50.086 22.468 50.086 50.087s-22.468 50.086-50.086 50.086z"
        />
    </svg>
);

export default TagIcon;
