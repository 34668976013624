import * as React from 'react';

const UserIcon = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        style={{
            enableBackground: 'new 0 0 31.04 31.039',
        }}
        viewBox="0 0 31.04 31.039"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill={props.color}
            d="M25.986 29.764c-.695.822-1.719 1.275-2.878 1.275H7.931c-1.159 0-2.182-.453-2.878-1.275-.697-.824-.978-1.906-.786-3.049l1.601-9.589c.383-2.294 2.586-4.161 4.909-4.161h.63A7.13 7.13 0 0 1 8.381 7.14c0-3.937 3.203-7.14 7.141-7.14s7.141 3.203 7.141 7.14a7.135 7.135 0 0 1-3.025 5.825h.629c2.324 0 4.527 1.866 4.91 4.161l1.6 9.589c.184 1.142-.094 2.224-.791 3.049z"
        />
    </svg>
);

export default UserIcon;
