import {
    FormControlLabel,
    Radio,
    RadioGroup as MUIRadioGroup,
} from '@mui/material';
import React from 'react';

export default function RadioGroup({ radios, ...props }) {
    return (
        <MUIRadioGroup {...props}>
            {radios.map(({ value, label, i }) => {
                return (
                    <FormControlLabel
                        key={i}
                        control={<Radio color="primary" key={i} />}
                        label={label}
                        value={value}
                    />
                );
            })}
        </MUIRadioGroup>
    );
}
