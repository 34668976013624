import Text from 'data/components/Text';
import ProductModal from '../../../ProductModal';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { isSecurity } from '../../../../../store/products/utils/predicates';

export default function ProductLabel({ product }) {
    const classes = useStyles({ product });
    const [open, setOpen] = useState(false);

    return (
        <>
            <Text
                noWrap
                onClick={() => {
                    !isSecurity(product) && setOpen(true);
                }}
                className={classes.container}
            >
                {product.label}
            </Text>
            <ProductModal open={open} setOpen={setOpen} product={product} />
        </>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        cursor: ({ product }) => (isSecurity(product) ? 'auto' : 'pointer'),
    },
}));
