import React from 'react';
import List from 'data/components/List';
import { makeStyles } from '@mui/styles';
import ListItem from 'data/components/ListItem';
import Button from 'data/components/Button';
import t from 'utils/Translator';
import AFKIcon from '../../../../assets/svgs/AFK';
import LightsOff from '../../../../assets/svgs/LightsOff';
import OpenedRollingShutter from '../../../../assets/svgs/OpenedRollingShutter';
import ClosedRollingShutter from '../../../../assets/svgs/ClosedRollingShutter';
import Tooltip from 'data/components/Tooltip';
import ListSubheader from '@mui/material/ListSubheader';

export default function Actions(props) {
    const classes = useStyles();

    return (
        <List
            dense
            subheader={
                <ListSubheader className={classes.listSubheader}>
                    Raccourcis
                </ListSubheader>
            }
            className={classes.container}
        >
            <ListItem className={classes.item}>
                {props.productsCategories?.lighting.length > 0 && (
                    <Tooltip title={t.get('actions.lightsOff')}>
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="outlined"
                                className={classes.button}
                            >
                                <LightsOff
                                    height={35}
                                    width={35}
                                    color={'#00988E'}
                                />
                            </Button>
                        </div>
                    </Tooltip>
                )}
                <Tooltip title={t.get('actions.leaving')}>
                    <div className={classes.buttonContainer}>
                        <Button variant="outlined" className={classes.button}>
                            <AFKIcon height={35} width={35} color={'#00988E'} />
                        </Button>
                    </div>
                </Tooltip>
            </ListItem>
            <ListItem className={classes.item}>
                {props.productsCategories?.shutters.length > 0 && (
                    <Tooltip title={t.get('actions.shuttersOpen')}>
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="outlined"
                                className={classes.button}
                            >
                                <OpenedRollingShutter
                                    height={35}
                                    width={35}
                                    color={'#00988E'}
                                />
                            </Button>
                        </div>
                    </Tooltip>
                )}
                {props.productsCategories?.shutters.length > 0 && (
                    <Tooltip title={t.get('actions.shuttersClose')}>
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="outlined"
                                className={classes.button}
                            >
                                <ClosedRollingShutter
                                    height={35}
                                    width={35}
                                    color={'#00988E'}
                                />
                            </Button>
                        </div>
                    </Tooltip>
                )}
            </ListItem>
        </List>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        justifyContent: 'center',
        alignContent: 'center',
    },
    item: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        width: '100%',
    },
    button: {
        width: '95%',
        alignSelf: 'center',
    },
    buttonContainer: {
        width: '100%',
    },
    listSubheader: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
    },
}));
