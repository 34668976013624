import React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from 'data/components/Paper';
import GroupTitle from './components/GroupTitle';
import List from 'data/components/List';
import Divider from 'data/components/Divider';
import AccordionProducts from 'data/components/AccordionProducts';
import Grid from 'data/components/Grid';
import Product from './components/Product';

export default function ProductsGroup({ productIds, group, filter }) {
    const classes = useStyles();

    const renderContent = () => {
        return (
            <List className={classes.container}>
                {productIds.map((id, i) => {
                    return (
                        <div key={i}>
                            <Product id={id} />
                            <Divider />
                        </div>
                    );
                })}
            </List>
        );
    };

    if (!productIds.length) return null;
    return (
        <Grid item lg={4} md={6} sm={6} xs={12}>
            <Paper elevation={5} className={classes.paper}>
                <AccordionProducts
                    header={<GroupTitle group={group} filter={filter} />}
                    content={renderContent()}
                />
            </Paper>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        padding: 2,
        width: '100%',
    },
    paper: {
        width: '100%',
    },
}));
