import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import Tickets from './api';
import moment from 'moment';

const adapter = createEntityAdapter();
const selectors = adapter.getSelectors(({ tickets }) => tickets);
export const slice = createSlice({
    name: 'tickets',
    initialState: adapter.getInitialState(),
    reducers: {
        setAll: adapter.setAll,
        setOne: adapter.setOne,
        updateOne: adapter.updateOne,
        removeOne: adapter.removeOne,
    },
});

export const ticketsActions = slice.actions;
export const ticketsReducer = slice.reducer;
export const ticketsSelectors = { ...selectors };

export const getHomeTickets = () => async dispatch => {
    const tickets = await Tickets.getTickets();

    await dispatch(ticketsActions.setAll(tickets));
};

export const getTicketHistory = async ticketId => {
    const history = await Tickets.getTicket(ticketId);

    return history;
};

export const createTicket = (label, description, type) => async dispatch => {
    const tickets = await Tickets.createTicket(label, description, type);

    await dispatch(ticketsActions.setAll(tickets));
};
