import Http from 'utils/Http';

const api = {
    getTickets: () =>
        Http.get('tickets').then(resp => {
            return resp.tickets;
        }),
    getTicket: ticketId =>
        Http.get(`tickets/${ticketId}`).then(resp => {
            return resp.ticket;
        }),
    createTicket: (label, description, type) =>
        Http.post('tickets', {
            label,
            description,
            type,
        }).then(resp => {
            return resp.tickets;
        }),
};

export default api;
