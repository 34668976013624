import { Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import ByDaySelector from '../../ByDaySelector';
import ModulesSlider from '../../ModulesSlider';
import { useSelector } from 'react-redux';
import { productsSelectors } from '../../../../store/products/selectors';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import Slider from 'react-input-slider';
import TemperatureControls from '../../TemperatureControls/TemperatureControls';
import ModuleStateSelector from '../../ModuleStateSelector';
import { getModuleKey } from '../../../../utils';

const PlanningModalForm = props => {
    const classes = useStyles();
    const [selectedPeriod, setSelectedPeriod] = useState(
        props?.selectedItem ? [parseInt(props?.selectedItem?.num_of_day)] : 0,
    );
    const [selectedItem, setSelectedItem] = useState(
        useSelector(state =>
            productsSelectors.selectById(
                state,
                getModuleKey(props?.selectedItem),
            ),
        ),
    );
    const [timePickerValue, setTimePickerValue] = useState(
        props?.selectedItem?.event_time ? props.selectedItem.event_time : '10',
    );
    const [sliderState, setSliderState] = useState({
        x: props?.selectedItem?.type_value ? props.selectedItem.type_value : 10,
        y: 0,
    });
    const [moduleStateValue, setModuleStateValue] = useState(
        props?.selectedItem?.type_value
            ? props?.selectedItem?.type_value
            : 'ON',
    );

    const products = useSelector(state => productsSelectors.selectAll(state));

    let filteredProducts = [];

    products.map(product => {
        if (product.line_type !== 'clamp') {
            filteredProducts.push(product);
        }

        return null;
    });

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.container}
        >
            {/*<h1>Nouvel Évènement</h1>*/}
            {/*<div className={classes.form}>*/}
            {/*    <div style={{ height: 40, margin: 12 }}>*/}
            {/*        <ByDaySelector*/}
            {/*            onChange={setSelectedPeriod}*/}
            {/*            value={selectedPeriod}*/}
            {/*            multiple*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div*/}
            {/*        style={{*/}
            {/*            height: 100,*/}
            {/*            marginTop: 24,*/}
            {/*            // marginBottom: 24,*/}
            {/*            width: '80%',*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <ModulesSlider*/}
            {/*            products={filteredProducts}*/}
            {/*            setSelectedItem={setSelectedItem}*/}
            {/*            selectedItem={selectedItem}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div className={classes.controls}>*/}
            {/*        <div className={classes.timePicker}>*/}
            {/*            <LocalizationProvider dateAdapter={DateAdapter}>*/}
            {/*                <TimePicker*/}
            {/*                    label="Horaires déclenchement"*/}
            {/*                    value={timePickerValue}*/}
            {/*                    onChange={newValue => {*/}
            {/*                        setTimePickerValue(newValue);*/}
            {/*                    }}*/}
            {/*                    renderInput={params => (*/}
            {/*                        <TextField {...params} />*/}
            {/*                    )}*/}
            {/*                    minutesStep={15}*/}
            {/*                />*/}
            {/*            </LocalizationProvider>*/}
            {/*        </div>*/}

            {/*        <div className={classes.productControls}>*/}
            {/*            {selectedItem?.module_type === 'shutterstore' && (*/}
            {/*                <Slider*/}
            {/*                    axis="x"*/}
            {/*                    x={*/}
            {/*                        props.selectedItem?.value*/}
            {/*                            ? props.selectedItem?.value*/}
            {/*                            : sliderState.x*/}
            {/*                    }*/}
            {/*                    onChange={({ x }) =>*/}
            {/*                        setSliderState(state => ({ ...state, x }))*/}
            {/*                    }*/}
            {/*                />*/}
            {/*            )}*/}

            {/*            {selectedItem?.module_type === 'thermostat' && (*/}
            {/*                <TemperatureControls module={selectedItem} />*/}
            {/*            )}*/}

            {/*            {selectedItem?.module_type === 'lighting' ||*/}
            {/*                (selectedItem?.module_type === 'ecs' && (*/}
            {/*                    <ModuleStateSelector*/}
            {/*                        module={selectedItem}*/}
            {/*                        event={props.selectedItem}*/}
            {/*                        stateValue={moduleStateValue}*/}
            {/*                        onClick={() =>*/}
            {/*                            moduleStateValue === 'ON'*/}
            {/*                                ? setModuleStateValue('OFF')*/}
            {/*                                : setModuleStateValue('ON')*/}
            {/*                        }*/}
            {/*                    />*/}
            {/*                ))}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 3,
        backgroundColor: theme.palette.common.white,
        height: 300,
        width: 650,
        flexDirection: 'column',
    },
    timePicker: { width: '50%', margin: 24 },
    productControls: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        marginLeft: 24,
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '80%',
    },
}));

export default PlanningModalForm;
