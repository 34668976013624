import React, { useState } from 'react';
import ListItem from 'data/components/ListItem';
import ListItemText from 'data/components/ListItemText';
import Grid from 'data/components/Grid';
import { isClamp_measures } from 'store/products/utils/predicates';
import ProductLabel from '../ProductLabel';
import Button from '@mui/material/Button';
import Modal from '../../../../Modals/Modal';
import InstantWattAreaChart from '../../../../../../navigation/screens/Stats/Charts/AreaChart';

export default function Measure({ product, setOpenModal }) {
    const [isOpen, setIsOpen] = useState(false);

    const renderData = () => {
        if (isClamp_measures(product)) {
            return `${product?.instant || 0} W`;
        }
        return `${product?.last_data?.global?.watth || 0} kWh`;
    };

    return (
        <ListItem>
            <ListItemText>
                <Grid container justifyContent="space-between">
                    <Grid item xs={6}>
                        <ProductLabel product={product} />
                    </Grid>
                    <Grid item container justifyContent="flex-end" xs={6}>
                        {isClamp_measures(product) ? (
                            <Button
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                            >
                                {renderData()}
                            </Button>
                        ) : (
                            renderData()
                        )}
                    </Grid>
                </Grid>
            </ListItemText>
            {isClamp_measures(product) && (
                <Modal
                    open={isOpen}
                    closeFunction={() => setIsOpen(false)}
                    statsModal
                >
                    <h3 style={{ color: '#00988e' }}>
                        Mesure instantanée - {product.label}
                    </h3>
                    <InstantWattAreaChart instantData={product.instant} />
                </Modal>
            )}
        </ListItem>
    );
}
