import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { getTicketHistory } from '../../../../../store/tickets/ticketSlice';
import { makeStyles } from '@mui/styles';
import List from '../../../List';
import ListItem from '../../../ListItem';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TicketIcon from '../../../../../assets/svgs/TicketIcon';
import UserIcon from '../../../../../assets/svgs/UserIcon';
import Home from '../../../Scheme/Home';
import moment from 'moment';
import TagIcon from '../../../../../assets/svgs/TagIcon';
import BubbleIcon from '../../../../../assets/svgs/BubbleIcon';
import t from 'utils/Translator';

const TicketHistory = props => {
    const classes = useStyles();
    const [history, setHistory] = useState([]);

    useEffect(() => {
        const fetchHistory = async () => {
            const history = await getTicketHistory(props.ticket.id);
            return history;
        };

        fetchHistory().then(resp => setHistory(resp.history));
    }, []);

    return (
        <Box className={classes.container}>
            <Box className={classes.title}>
                <TicketIcon width={40} height={40} color={'#eee'} />

                <Typography
                    sx={{
                        fontSize: 22,
                        fontWeight: 'bold',
                        color: '#eee',
                    }}
                >
                    Détail du ticket: {props.ticket.ref}
                </Typography>
            </Box>
            <Box className={classes.content}>
                <Box className={classes.ticketInfos}>
                    <Box className={classes.inputs}>
                        <UserIcon width={30} height={30} color={'#ccc'} />
                        <TextField
                            sx={{ width: '70%' }}
                            value={moment(props.ticket.created_at).format(
                                'DD/MM/YYYY',
                            )}
                            disabled
                            helperText={'Date de création'}
                            inputProps={{
                                style: {
                                    height: 10,
                                },
                            }}
                        />
                    </Box>
                    <Box className={classes.inputs}>
                        <TagIcon width={30} height={30} color={'#ccc'} />
                        <TextField
                            sx={{ width: '70%' }}
                            value={t.get(`tickets.state.${props.ticket.state}`)}
                            disabled
                            helperText={'État'}
                            inputProps={{
                                style: {
                                    height: 10,
                                },
                            }}
                        />
                    </Box>
                    <Box className={classes.inputs}>
                        <TagIcon width={30} height={30} color={'#ccc'} />
                        <TextField
                            sx={{ width: '70%' }}
                            value={props.ticket.label}
                            disabled
                            helperText={'Libellé'}
                            inputProps={{
                                style: {
                                    height: 10,
                                },
                            }}
                        />
                    </Box>
                    <Box className={classes.inputs}>
                        <Home width={30} height={30} color={'#ccc'} />
                        <TextField
                            sx={{ width: '70%' }}
                            value={t.get(`tickets.type.${props.ticket.type}`)}
                            disabled
                            helperText={'Catégorie'}
                            inputProps={{
                                style: {
                                    height: 10,
                                },
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            width: '100%',
                        }}
                    >
                        <BubbleIcon width={40} height={40} color={'#ccc'} />
                        <TextField
                            disabled
                            value={props.ticket.description}
                            helperText={'Description'}
                            sx={{
                                width: '80%',
                            }}
                            multiline
                            inputProps={{
                                style: {
                                    height: 100,
                                },
                            }}
                        />
                    </Box>
                </Box>
                <List className={classes.list}>
                    {history.map(e => {
                        return (
                            <ListItem
                                key={e.created_at}
                                className={classes.listItem}
                            >
                                {moment(e.created_at).format(
                                    'DD/MM/YYYY HH:MM',
                                )}{' '}
                                - {e.type}
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        height: '100%',
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 12,
        width: '100%',
        borderRadius: 2,
        height: 80,
        padding: 2,
        backgroundColor: '#00988E',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
    },
    ticketInfos: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: '60%',
        height: '100%',
    },
    inputs: {
        display: 'flex',
        alignItems: 'center',
        height: '25%',
        gap: 8,
        width: '50%',
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
        height: '100%',
        gap: 8,
    },
    listItem: {
        width: '100%',
        height: 50,
        borderRadius: 2,
        backgroundColor: '#eee',
        '&:hover': {
            backgroundColor: '#e8e8e8',
        },
    },
}));

export default TicketHistory;
