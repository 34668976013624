import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '../Grid';
import Typography from '@mui/material/Typography';
import FacebookIcon from '../../../assets/svgs/Facebook';
import TwitterIcon from '../../../assets/svgs/Twitter';
import YoutubeIcon from '../../../assets/svgs/Youtube';
import LinkedinIcon from '../../../assets/svgs/Linkedin';
import Card from '@mui/material/Card';
import Button from '../Button';

const SupportTabs = props => {
    const classes = useStyles();

    return (
        <Grid container className={classes.container}>
            <Card
                sx={{
                    marginTop: 2,
                    width: '100%',
                    height: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                }}
            >
                <Button className={classes.buttonTab}>
                    <Typography className={classes.tabText}>
                        Des Incidents
                    </Typography>
                </Button>
                <Button className={classes.buttonTab}>
                    <Typography className={classes.tabText}>
                        Nouvelles Docs
                    </Typography>
                </Button>
                <Button className={classes.buttonTab}>
                    <Typography className={classes.tabText}>
                        Tutoriels
                    </Typography>
                </Button>
                <Button className={classes.buttonTab}>
                    <Typography className={classes.tabText}>FAQ</Typography>
                </Button>
            </Card>
            <Card className={classes.infosCard}>
                <div className={classes.title}>Suivez nous sur :</div>
                <div className={classes.icons}>
                    <a
                        href="https://www.facebook.com/FHE.France/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FacebookIcon
                            width={50}
                            height={50}
                            color={'#00988E'}
                        />
                    </a>
                    <a
                        href="https://twitter.com/FHE_France"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <TwitterIcon width={50} height={50} color={'#00988E'} />
                    </a>
                    <a
                        href="https://www.youtube.com/channel/UCevsyFrMSyHlnhmV3nk3zsw"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <YoutubeIcon width={50} height={50} color={'#00988E'} />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/fhe-group/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <LinkedinIcon
                            width={50}
                            height={50}
                            color={'#00988E'}
                        />
                    </a>
                </div>
            </Card>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        color: theme.palette.common.black,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    buttonTab: {
        width: '100%',
        padding: 1,
        '&:hover': {
            backgroundColor: '#00988E',
            opacity: 0.7,
        },
    },
    tabText: {
        fontSize: 28,
        color: '#000',
    },
    infosCard: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '10%',
    },
    title: {
        display: 'flex',
        color: theme.palette.text.primary,
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 24,
    },
    icons: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100%',
    },
}));

export default SupportTabs;
