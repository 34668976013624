import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Grid from 'data/components/Grid';
import UserCards from './components/UserCards';
import {
    generatorsSelectors,
    selectGeneratorsIds,
} from 'store/generators/generatorSlice';
import GeneratorCards from './components/GeneratorCards';
import { selectCurrentUser } from 'store/auth/authSlice';
import { selectGenerators } from '../../../store/generators/generatorSlice';

const Account = props => {
    const classes = useStyle();
    const user = useSelector(selectCurrentUser);
    const generators = useSelector(generatorsSelectors.selectAll);

    if (!user) return null;
    return (
        <Grid
            container
            spacing={6}
            justifyContent={'center'}
            className={classes.container}
        >
            <UserCards user={user} />
            <Grid container item style={{ alignSelf: 'center' }}>
                <GeneratorCards generators={generators} />
            </Grid>
        </Grid>
    );
};

const useStyle = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(4),
    },
}));

export default Account;
