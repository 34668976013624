import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectGeneratorsIds } from '../../../../store/generators/generatorSlice';

const AutoConsoBarChart = props => {
    const [consoVisible, setConsoVisible] = useState(false);
    const [prodVisible, setProdVisible] = useState(false);
    const [autoConsoVisible, setAutoConsoVisible] = useState(false);
    const generators = useSelector(selectGeneratorsIds);
    const hasProd = generators.length >= 1;

    const setVisible = value => {
        if (value === 'Conso') {
            setConsoVisible(!consoVisible);
        } else if (value === 'Prod') {
            setProdVisible(!prodVisible);
        } else {
            setAutoConsoVisible(!autoConsoVisible);
        }
    };

    return (
        <ResponsiveContainer width={props.width} height={props.height}>
            <BarChart data={props.consoProdStats} margin={props.margin}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey={'date'}
                    scale={'band'}
                    xAxisId={0}
                    type="category"
                    tickFormatter={value => {
                        return (
                            (props.filter === 'hours' &&
                                `${moment(value).format('HH')}h`) ||
                            (props.filter === 'rollingHours' &&
                                `${moment(value).format('HH')}h`) ||
                            (props.filter === 'rollingDays' &&
                                moment(value).format('D MMM')) ||
                            (props.filter === 'rollingMonths' &&
                                moment(value).format('MMM YYYY')) ||
                            (props.filter === 'rollingYears' &&
                                moment(value).format('YYYY'))
                        );
                    }}
                />
                <XAxis dataKey={'date'} scale={'band'} xAxisId={1} hide />
                <YAxis
                    tickFormatter={value => {
                        return value / 1000;
                    }}
                />
                <Tooltip
                    labelFormatter={label => {
                        return `${moment(label).format('D MMM YYYY HH')}h`;
                    }}
                    formatter={(value, name, props) => {
                        return Math.round((value * 100) / 1000) / 100;
                    }}
                    cursor={{ fill: 'transparent' }}
                />
                {!props.isDetail && (
                    <Legend onClick={e => setVisible(e.value)} />
                )}
                <Bar
                    dataKey={props.isDetail ? 'watth' : 'wh'}
                    unit={' kWh'}
                    name="Conso"
                    fill="#2196F3"
                    xAxisId={0}
                    hide={consoVisible}
                />
                {!props.isDetail && hasProd && (
                    <Bar
                        dataKey="watth"
                        unit={' kWh'}
                        name="Prod"
                        fill="#4CAF50"
                        xAxisId={0}
                        hide={prodVisible}
                    />
                )}
                {props.autoconso && hasProd && (
                    <Bar
                        dataKey="wh_autoconsumed"
                        unit={' kWh'}
                        name="AutoConso"
                        xAxisId={1}
                        fill="#fa8816"
                        barSize={6}
                        hide={autoConsoVisible}
                    />
                )}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default AutoConsoBarChart;
