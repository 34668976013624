import React from 'react';
import { makeStyles } from '@mui/styles';
import Logo from 'assets/svgs/logo_layout_light.svg';
import LogoDark from 'assets/svgs/logo_layout_dark.svg';

const BackgroundImage = props => {
    const classes = useStyles();
    return <div className={classes.background} />;
};

const useStyles = makeStyles(theme => ({
    background: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        background:
            theme.palette.mode === 'dark'
                ? `url(${LogoDark}) no-repeat center`
                : `url(${Logo}) no-repeat center`,
        backgroundColor: theme.palette.background.default,
        backgroundPosition: '50% 10%',
        backgroundSize: 800,
        zIndex: '-1',
    },
}));

export default BackgroundImage;
