import AccordionGenerators from '../../../../data/components/AccordionGenerators';
import Grid from '../../../../data/components/Grid';
import Text from '../../../../data/components/Text';
import Box from '@mui/material/Box';
import OrientationIcon from '../../../../assets/svgs/OrientationIcon';
import t from '../../../../utils/Translator';
import VentilationIcon from '../../../../assets/svgs/VentilationIcon';
import PowerIcon from '../../../../assets/svgs/PowerIcon';
import AngleIcon from '../../../../assets/svgs/AngleIcon';
import Button from '../../../../data/components/Button';
import PencilIcon from '../../../../assets/svgs/Pencil';
import Card from '@mui/material/Card';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    createGenerator,
    deleteGenerator,
    updateGenerator,
} from '../../../../store/generators/generatorSlice';

const GeneratorCard = props => {
    const classes = useStyle();
    const [edition, setEdition] = useState(
        props.generator.added ? true : false,
    );
    const [orientation, setOrientation] = useState(
        t.get(`generators.orientation.${props.generator.orientation}`),
    );
    const [ventilation, setVentilation] = useState(
        t.get(`generators.moduleType.${props.generator.module_type}`),
    );
    const [power, setPower] = useState(props.generator.power);
    const [pitch, setPitch] = useState(props.generator.roof_pitch);

    const updateGenerator = () => {
        if (edition) {
            props.updateGenerator(props.generator.id, {
                orientation,
                module_type: ventilation,
                power,
                roof_pitch: pitch,
            });
            setEdition(false);
        } else if (!edition) {
            console.log(props.generator);
            setEdition(true);
        }
    };

    const createGenerator = () => {
        if (edition) {
            props.createGenerator({
                orientation,
                module_type: ventilation,
                power,
                roof_pitch: pitch,
            });
            setEdition(false);
            props.setEdition(false);
        } else if (!edition) {
            setEdition(true);
        }
    };

    return (
        <Card
            key={props.index}
            elevation={5}
            className={classes.generatorPaper}
        >
            <AccordionGenerators
                defaultExpanded={true}
                header={
                    <Grid container>
                        <Grid item xs={12} className={classes.titleContainer}>
                            <Text noWrap className={classes.title}>
                                PV n°
                                {props.index + 1}
                            </Text>
                        </Grid>
                    </Grid>
                }
                content={
                    <Grid container className={classes.rowContent}>
                        <Box className={classes.box}>
                            <OrientationIcon
                                width={30}
                                height={30}
                                color={'#00988e'}
                            />
                            <FormControl>
                                <InputLabel
                                    id="Orientation"
                                    sx={{ marginLeft: 2 }}
                                >
                                    Orientation
                                </InputLabel>
                                <Select
                                    className={classes.row}
                                    value={orientation}
                                    label="Orientation"
                                    labelId="Orientation"
                                    onChange={e =>
                                        setOrientation(e.target.value)
                                    }
                                    inputProps={{
                                        readOnly: !edition,
                                    }}
                                >
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.OSO',
                                        )}
                                    >
                                        {t.get('generators.orientation.OSO')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.SSO',
                                        )}
                                    >
                                        {t.get('generators.orientation.SSO')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.ESE',
                                        )}
                                    >
                                        {t.get('generators.orientation.ESE')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.SSE',
                                        )}
                                    >
                                        {t.get('generators.orientation.SSE')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.SE',
                                        )}
                                    >
                                        {t.get('generators.orientation.SE')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.SO',
                                        )}
                                    >
                                        {t.get('generators.orientation.SO')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.S',
                                        )}
                                    >
                                        {t.get('generators.orientation.S')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.O',
                                        )}
                                    >
                                        {t.get('generators.orientation.O')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.ONO',
                                        )}
                                    >
                                        {t.get('generators.orientation.ONO')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.NNO',
                                        )}
                                    >
                                        {t.get('generators.orientation.NNO')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.ENE',
                                        )}
                                    >
                                        {t.get('generators.orientation.ENE')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.NNE',
                                        )}
                                    >
                                        {t.get('generators.orientation.NNE')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.NE',
                                        )}
                                    >
                                        {t.get('generators.orientation.NE')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.NO',
                                        )}
                                    >
                                        {t.get('generators.orientation.NO')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.N',
                                        )}
                                    >
                                        {t.get('generators.orientation.N')}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.orientation.E',
                                        )}
                                    >
                                        {t.get('generators.orientation.E')}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className={classes.box}>
                            <VentilationIcon
                                width={30}
                                height={30}
                                color={'#00988e'}
                            />
                            <FormControl>
                                <InputLabel
                                    id="Ventilation"
                                    sx={{ marginLeft: 2 }}
                                >
                                    Ventilation
                                </InputLabel>
                                <Select
                                    className={classes.row}
                                    value={ventilation}
                                    label="Ventilation"
                                    labelId="Ventilation"
                                    onChange={e =>
                                        setVentilation(e.target.value)
                                    }
                                    inputProps={{
                                        readOnly: !edition,
                                    }}
                                >
                                    <MenuItem
                                        value={t.get(
                                            'generators.moduleType.well-ventilated',
                                        )}
                                    >
                                        {t.get(
                                            'generators.moduleType.well-ventilated',
                                        )}
                                    </MenuItem>
                                    <MenuItem
                                        value={t.get(
                                            'generators.moduleType.ventilated',
                                        )}
                                    >
                                        {t.get(
                                            'generators.moduleType.ventilated',
                                        )}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className={classes.box}>
                            <PowerIcon
                                width={30}
                                height={30}
                                color={'#00988e'}
                            />
                            <TextField
                                className={classes.row}
                                onChange={e => setPower(e.target.value)}
                                label={'Power'}
                                defaultValue={power !== null ? power : 0}
                                InputProps={{
                                    readOnly: !edition,
                                }}
                                sx={{ marginRight: 1 }}
                            />
                            kW
                        </Box>
                        <Box className={classes.box}>
                            <AngleIcon
                                width={30}
                                height={30}
                                color={'#00988e'}
                            />
                            <TextField
                                className={classes.row}
                                onChange={e => setPitch(e.target.value)}
                                label={'Pitch'}
                                defaultValue={pitch !== null ? pitch : 0}
                                InputProps={{
                                    readOnly: !edition,
                                }}
                                sx={{ marginRight: 1 }}
                            />
                            °
                        </Box>

                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Button
                                onClick={() => {
                                    !props.generator.added
                                        ? updateGenerator()
                                        : createGenerator();
                                }}
                                color="primary"
                                variant="outlined"
                                fullWidth
                                sx={edition && { marginLeft: -2 }}
                            >
                                {!edition && (
                                    <PencilIcon
                                        width={20}
                                        height={25}
                                        color={'#00988E'}
                                    />
                                )}{' '}
                                {edition &&
                                    !props.generator.added &&
                                    'Enregistrer'}
                                {props.generator.added && 'Ajouter'}
                            </Button>
                            {edition && (
                                <Button
                                    onClick={() => {
                                        if (edition) {
                                            !props.generator.added
                                                ? props.deleteGenerator(
                                                      props.generator.id,
                                                  )
                                                : props.resetGenerators();
                                            setEdition(false);
                                            props.setEdition(false);
                                        }
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        marginLeft: 2,
                                        color: 'red',
                                        borderColor: 'red',
                                    }}
                                >
                                    {props.generator.added
                                        ? 'Annuler'
                                        : 'Supprimer'}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                }
            />
        </Card>
    );
};

const useStyle = makeStyles(theme => ({
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: 20,
    },
    rowContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    box: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: 8,
    },
    row: {
        minWidth: 140,
        marginLeft: 14,
        textAlign: 'baseline',
    },
    generatorPaper: {
        display: 'flex',
        flexDirection: 'column',
        padding: 12,
        margin: 12,
        backgroundColor: theme.palette.background.default,
    },
}));

const mapDispatch = dispatch =>
    bindActionCreators(
        {
            createGenerator,
            updateGenerator,
            deleteGenerator,
        },
        dispatch,
    );

export default connect(null, mapDispatch)(GeneratorCard);
