import { makeStyles } from '@mui/styles';
import { Box, TextField } from '@mui/material';
import t from '../../../../utils/Translator';
import React from 'react';
import Button from '../../Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    setCurrentUser,
    setNewPassword,
} from '../../../../store/auth/authSlice';
import FormControl from '../../FormControl';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { setTokenToHttpHeader, setTokenToStorages } from '../../../../utils';
import LogoDark from '../../../../assets/svgs/logo_layout_dark.svg';
import Logo from '../../../../assets/svgs/logo_layout_light.svg';

const initialValues = {
    email: '',
    recoveryPassword: '',
    newPassword: '',
};

const validationSchema = Yup.object({
    email: Yup.string().required('Enter a valid email'),
    recoveryPassword: Yup.string().required('Enter a valid password'),
    newPassword: Yup.string().required('Enter a valid password'),
});

const RecoverAndAuthModal = props => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            const { payload } = await setNewPassword(
                values.email,
                values.recoveryPassword,
                values.newPassword,
            );

            if (payload) {
                await dispatch(setCurrentUser(payload.profile));
                setTokenToHttpHeader(payload.accessToken);
                setTokenToStorages(false, payload.accessToken);
            }
            return toDashboard(payload);
        },
    });

    const toDashboard = payload => {
        if (payload) {
            props.close();
            history.push('/dashboard');
        }
    };

    return (
        <Box className={classes.container}>
            <form onSubmit={formik.handleSubmit} className={classes.form}>
                <FormControl className={classes.content}>
                    <TextField
                        fullWidth
                        variant="standard"
                        id="email"
                        name="email"
                        label={t.get('login.email')}
                        value={(formik.values.email = props.recoverEmail)}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                        fullWidth
                        variant="standard"
                        id="recoveryPassword"
                        name="recoveryPassword"
                        label={'Mot de passe de récupération'}
                        value={formik.values.recoveryPassword}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.recoveryPassword &&
                            Boolean(formik.errors.recoveryPassword)
                        }
                        helperText={
                            formik.touched.recoveryPassword &&
                            formik.errors.recoveryPassword
                        }
                        className={classes.input}
                    />
                    <TextField
                        fullWidth
                        variant="standard"
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        label={'Nouveau mot de passe'}
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.newPassword &&
                            Boolean(formik.errors.newPassword)
                        }
                        helperText={
                            formik.touched.newPassword &&
                            formik.errors.newPassword
                        }
                        className={classes.input}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        type={'submit'}
                    >
                        Envoyer
                    </Button>
                </FormControl>
                <div className={classes.background} />
            </form>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    form: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '80%',
        width: '80%',
    },
    input: {
        marginTop: 12,
    },
    submit: {
        position: 'absolute',
        marginTop: 12,
        right: 0,
    },
    background: {
        position: 'fixed',
        width: '80%',
        height: '80%',
        background:
            theme.palette.mode === 'dark'
                ? `url(${LogoDark}) no-repeat center`
                : `url(${Logo}) no-repeat center`,
        backgroundColor: theme.palette.background.default,
        backgroundPosition: '50% 10%',
        backgroundSize: 400,
        zIndex: '-1',
    },
}));

export default RecoverAndAuthModal;
