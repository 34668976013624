import React, { useState } from 'react';
import Grid from '../../Grid';
import { Collapse } from '@mui/material';
import Paper from '../../Paper';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import LeafIcon from '../../../../assets/svgs/Leaf';
import t from '../../../../utils/Translator';
import Text from '../../Text';
import Tooltip from '../../Tooltip';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
const translations = require('../../../../lang/fr.json');

export default function EcoTipsWidget(props) {
    const [collapsed, setCollapsed] = useState(true);
    const classes = useStyles(collapsed);
    const theme = useTheme();

    // divide container in two and add first group
    const groupArray = [
        'shutters',
        'switches',
        'lighting',
        'securities',
        'measures',
        'temperatures',
    ];

    let randomGroup = groupArray[Math.floor(Math.random() * groupArray.length)];
    let groupEcoTips = Object.keys(translations.ecoTips.groups[randomGroup]);

    let randomEcoTip =
        groupEcoTips[Math.floor(Math.random() * groupEcoTips.length)];

    let ecoTip = t.get(`ecoTips.groups.${randomGroup}.${randomEcoTip}`);

    return (
        <Grid style={{ justifyContent: 'center' }} container item xs={6}>
            <Collapse
                orientation={'horizontal'}
                in={collapsed}
                collapsedSize={280}
                sx={{
                    boxShadow:
                        !collapsed && '5px 5px 15px 5px rgba(0,0,0,0.20)',
                    height: 200,
                }}
            >
                <Paper elevation={5} className={classes.container}>
                    <Tooltip title={t.get('ecoTips.title')}>
                        <Button
                            onClick={() => setCollapsed(!collapsed)}
                            sx={{
                                padding: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 280,
                            }}
                            component={'div'}
                        >
                            <LeafIcon
                                color={theme.palette.primary.main}
                                height={100}
                                width={120}
                            />
                        </Button>
                    </Tooltip>
                    <Box className={classes.box}>
                        <Text
                            className={classes.text}
                            sx={{
                                padding: 2,
                                fontStyle: 'italic',
                                fontSize: 20,
                            }}
                        >
                            {ecoTip}
                        </Text>
                    </Box>
                </Paper>
            </Collapse>
        </Grid>
    );
}

const useStyles = makeStyles((theme, collapsed) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: 700,
        height: '100%',
    },
    box: {
        padding: 8,
        width: 420,
        alignSelf: 'center',
        justifyContent: 'center',
    },
    text: {
        color: theme.palette.text.primary,
        width: 380,
        textAlign: 'center',
    },
}));
