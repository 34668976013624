import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import List from '../../../List';
import ListItem from '../../../ListItem';
import React, { useState } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import StepLabel from '@mui/material/StepLabel';
import t from 'utils/Translator';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createTicket } from '../../../../../store/tickets/ticketSlice';

const TicketWizard = props => {
    const classes = useStyles();
    const [label, setLabel] = useState(false);
    const [description, setDescription] = useState(false);
    const [type, setType] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const StyledStepLabel = withStyles(theme => ({
        label: {
            color: theme.palette.text.primary,
        },
    }))(StepLabel);

    const steps = ['Déclarer un incident', 'Complétez votre ticket', 'Terminé'];
    return (
        <Box className={classes.container}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                        <Step key={label} {...stepProps}>
                            <StyledStepLabel {...labelProps}>
                                {label}
                            </StyledStepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {!type ? (
                <List className={classes.list}>
                    <ListItem
                        className={classes.listItem}
                        onClick={() => {
                            handleNext();
                            setType('noData');
                        }}
                    >
                        {t.get('tickets.type.noData')}
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        onClick={() => {
                            handleNext();
                            setType('noControl');
                        }}
                    >
                        {t.get('tickets.type.noControl')}
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        onClick={() => {
                            handleNext();
                            setType('noHotWater');
                        }}
                    >
                        {t.get('tickets.type.noHotWater')}
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        onClick={() => {
                            handleNext();
                            setType('systemDisconnected');
                        }}
                    >
                        {t.get('tickets.type.systemDisconnected')}
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        onClick={() => {
                            handleNext();
                            setType('other');
                        }}
                    >
                        {t.get('tickets.type.other')}
                    </ListItem>
                </List>
            ) : (
                <Box className={classes.content}>
                    <TextField
                        helperText={'Sujet'}
                        onChange={e => setLabel(e.target.value)}
                    />
                    <TextField
                        helperText={'Description'}
                        multiline
                        inputProps={{
                            style: {
                                height: 200,
                            },
                        }}
                        onChange={e => setDescription(e.target.value)}
                    />
                    <Box className={classes.buttonRow}>
                        <Button
                            onClick={() => {
                                setType(false);
                            }}
                            variant={'contained'}
                            sx={{
                                width: '45%',
                            }}
                        >
                            Retour
                        </Button>
                        <Button
                            onClick={async () => {
                                await props.createTicket(
                                    label,
                                    description,
                                    type,
                                );
                                props.close();
                            }}
                            variant={'contained'}
                            sx={{
                                width: '45%',
                            }}
                        >
                            Soumettre
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: 16,
    },
    listItem: {
        fontSize: 18,
        '&:hover': {
            backgroundColor: '#e8e8e8',
        },
    },
}));

export default TicketWizard;
