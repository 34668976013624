import React from 'react';
import { makeStyles } from '@mui/styles';
import backgroundImage from 'assets/backgroundLogin.jpg';

export default function BackgroundImageLogin(props) {
    const classes = useStyles();
    return <div className={classes.background} />;
}

const useStyles = makeStyles(theme => ({
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 30%',
        backgroundSize: 'cover',
        zIndex: '-1',
        filter: 'blur(10px)',
    },
}));
