import * as React from 'react';

const TicketsIcon = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 457.208 457.208"
        style={{
            enableBackground: 'new 0 0 457.208 457.208',
        }}
        width={props.width}
        height={props.height}
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill={props.color}
            d="M442.468 196.301c8.182-.142 14.74-6.814 14.74-14.998v-72.778c0-8.284-6.716-15-15-15H15c-8.284 0-15 6.716-15 15v72.768c0 3.996 1.595 7.828 4.431 10.644a14.946 14.946 0 0 0 11.153 4.346c17.732.105 32.125 14.564 32.125 32.321 0 17.764-14.405 32.226-32.146 32.32a32.664 32.664 0 0 0-.447-.009H15a14.993 14.993 0 0 0-10.565 4.353A14.996 14.996 0 0 0 0 275.915v72.768c0 8.284 6.716 15 15 15h427.208c8.284 0 15-6.716 15-15v-72.778c0-8.183-6.559-14.855-14.74-14.998-17.507-.303-31.749-14.794-31.749-32.303 0-17.509 14.242-32.001 31.749-32.303zm-61.072 104.212c0 8.284-6.716 15-15 15H90.813c-8.284 0-15-6.716-15-15v-143.82c0-8.284 6.716-15 15-15h275.583c8.284 0 15 6.716 15 15v143.82z"
        />
        <path
            fill={props.color}
            d="M105.813 171.693h159.396v113.82H105.813zM295.208 171.693h56.188v113.82h-56.188z"
        />
    </svg>
);

export default TicketsIcon;
