import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Stats from '../../../../../stats/api';
import moment from 'moment';
import AutoConsoBarChart from '../../../../../../navigation/screens/Stats/Charts/BarChart';
import t from '../../../../../../utils/Translator';
import RadioGroup from 'data/components/RadioGroup';
import FormControl from '../../../../FormControl';
import Grid from '../../../../Grid';
import Text from '../../../../Text';

export default function MeasuresTab({ product, setMaxWidth }) {
    const classes = useStyles();
    const [value, setValue] = useState('hours');
    const [stats, setStats] = useState([]);

    useEffect(() => {
        setMaxWidth('lg');
    }, []);

    const now = moment().format('X');

    const getProductStats = async () => {
        let filter;

        if (value === 'rollingHours') {
            filter = 'rolling_hours';
        } else if (value === 'rollingDays') {
            filter = 'rolling_days';
        } else if (value === 'rollingMonths') {
            filter = 'rolling_months';
        } else if (value === 'rollingYears') {
            filter = 'rolling_years';
        } else {
            filter = 'hours';
        }

        const productStats = await Stats.getProductStats(
            product.product_type,
            product.product_identifier,
            product.code,
            now,
            filter,
        );

        setStats(Object.values(productStats.detail.watth));
    };

    useEffect(() => {
        getProductStats();
    }, [value]);

    let total = 0;

    stats.forEach(stat => (total += stat.financial));

    return (
        <Grid className={classes.container}>
            <FormControl>
                <RadioGroup
                    row
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    radios={[
                        { value: 'hours', label: t.get('filters.today') },
                        {
                            value: 'rollingHours',
                            label: t.get('filters.rollingHours'),
                        },
                        {
                            value: 'rollingDays',
                            label: t.get('filters.rollingDays'),
                        },
                        {
                            value: 'rollingMonths',
                            label: t.get('filters.rollingMonths'),
                        },
                        {
                            value: 'rollingYears',
                            label: t.get('filters.rollingYears'),
                        },
                    ]}
                />
            </FormControl>
            <AutoConsoBarChart
                width={'90%'}
                height={300}
                consoProdStats={stats}
                isDetail={true}
                filter={value}
            />

            <Text
                style={{
                    margin: 24,
                    fontWeight: 600,
                }}
            >
                Consommation total: {Math.ceil(total * 1000)} Wh
            </Text>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    container: {},
}));
